<template>
  <a-modal v-model="modalVisible" title="选择设备" :width="900" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <div class="select-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="设备名称" prop="devicename">
          <a-input v-model.trim="queryParams.devicename" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="注册代码" prop="devicenum">
          <a-input v-model.trim="queryParams.devicenum" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="设备类型" prop="terminaltype">
          <a-select v-model="queryParams.terminaltype" style="width: 180px;">
            <a-select-option value="">请选择</a-select-option>
            <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item style="margin-left: 40%">
          <a-button @click="resetQueryForm">重置</a-button>
          <a-button type="primary" style="margin-left: 10px;" @click="getTableData(true)">查询</a-button>
          <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
        </a-form-model-item>

      </a-form-model>
      <div v-show="showAdvanced" class="advanced-query">
        <a-form-model ref="advancedForm" :model="formDatas" layout="inline" class="query-form">
          <a-form-model-item label="使用单位" prop="userdepname">
            <a-input v-model.trim="queryParams.userdepname" placeholder="请输入"></a-input>
<!--            <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
          </a-form-model-item>
          <a-form-model-item label="维保单位" prop="maintenancedepname">
            <a-input v-model.trim="queryParams.maintenancedepname" placeholder="请输入"></a-input>
<!--            <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='3')" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
          </a-form-model-item>
        </a-form-model>
      </div>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceinfoid" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
        <span slot="terminaltype" slot-scope="text">
          <span class="text">{{terminaltypesMap[text]||''}}</span>
        </span>
        <span slot="isonline" slot-scope="value">
          <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
        </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import pagination from '@/mixins/pagination'
import { getTerminaltypeListByCondition, getDeviceinfoListByCondition, addDevicegroupdev, deleteDevicegroupdev } from 'A/ai.js'
import {mapState} from "vuex";
import deptselect from "@/mixins/deptselect";
export default {
  mixins: [pagination,deptselect],
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    params: {
      default: null
    }
  },
  data() {
    return {
      showAdvanced:false,
      modalVisible: false,
      terminaltypes: [],
      queryParams: {
        devicename: '',
        devicenum: '',
        terminaltype: '',
        userdepname:'',
        maintenancedepname:'',
      },
      formDatas:{
        userdepid:'',
        maintenancedepid:'',
      },
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableLoading: false,
      tableColumns: [
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '设备型号',
          dataIndex: 'terminaltype',
          key: 'terminaltype',
          ellipsis: true,
          scopedSlots: { customRender: 'terminaltype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.deviceinfoid);
      return currentSelectKeys;
      // if(this.selectType == 'radio' && currentSelectKeys.length) {
      // }else {
      //   return [...new Set([...currentSelectKeys])];
      // }
    },
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.userdepid = val[val.length-1];
      }else {
        this.formDatas.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.formDatas.maintenancedepid = val[val.length-1];
      }else {
        this.formDatas.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
  },
  created() {
    // this.init();
    this.initDeptOptionsByUserdepid();
  },
  methods: {
    init() {
      console.log(this.selectedRows)
      this.selectedRows = [];
      // if(this.params) {
      //   // console.log(this.params)
      //   this.queryParams = {...this.queryParams, ...this.params};
      // }
      this.modalVisible = this.visible;
      this.getTerminaltypes();
      // this.getTableData(true);
    },
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
        }
        this.getTableData(true);
      }).catch(err => {
        this.getTableData(true);
      })
    },
    resetQueryForm() {
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        ...this.formDatas,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getDeviceinfoListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item.map(item => {
            item.deviceinfoid = '' + item.deviceinfoid;
            return item;
          });
          this.pagination.total = res.count;
        }
      })
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.deviceinfoid) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.deviceinfoid!=record.deviceinfoid)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.deviceinfoid) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.deviceinfoid!=record.deviceinfoid)
        })
      }
    },
    confirm() {
      // this.$emit('get-selected-rows', this.selectedRows);
      if(this.selectedRows.length) {
        let deviceinfoids = this.selectedRows.map(item => item.deviceinfoid).join('|');
        let params = {
          devicegroupid: this.params.devicegroupid,
          deviceinfoids: deviceinfoids,
        };
        addDevicegroupdev(params).then(res => {
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.$emit('get-operation-result', 'success', '操作成功');
            this.modalVisible = false;
          }
        })
      }
    }
  }
}
</script>