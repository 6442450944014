<template>
  <a-modal v-model="modalVisible" title="选择版本" :width="800" :centered="true">
    <template slot="footer">
      <a-button @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm" v-on:click="sendMsg">确认</a-button>
    </template>
    <div class="menu-modal-container">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline">
        <a-form-model-item label="设备模块类型" prop="devicemoduletype">
<!--          <a-select v-model="queryParams.devicemoduletype" style="width: 180px;" :disabled="!isDevice">-->
          <a-select v-model="queryParams.devicemoduletype" style="width: 180px;">
            <a-select-option v-for="(item, index) in devicemoduletypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="版本号" prop="version">
          <a-input v-model.trim="queryParams.version" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item label="">
          <a-button type="primary" @click="getTableData(true)">查询</a-button>
        </a-form-model-item>
      </a-form-model>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="deviceversionid" :row-selection="{ type: selectType, selectedRowKeys: selectedRowKeys, onSelect: onSelectRow, onSelectAll: onSelectAllRows }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" :pagination="false">
        <span slot="terminaltypeid" slot-scope="text">
          <span class="text" :title="getTerminalTypeStr(text)">{{getTerminalTypeStr(text)}}</span>
        </span>
        <span slot="devicemoduletype" slot-scope="text">
          <span class="text" :title="devicemoduletypesMap[text]||''">{{devicemoduletypesMap[text]||''}}</span>
        </span>
        <span slot="uploadtime" slot-scope="text">
          <span :title="text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">{{text ? moment(text, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
        </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </a-modal>
</template>
<script>
import moment from 'moment'
import devicemoduletypes from '@/json/devicemoduletypes'
import {
  getTerminaltypeListByCondition,
  getDeviceversionListByCondition,
  deleteDeviceversion,
  getDeviceinfoListByCondition, getDevicemoduleinfoListByCondition
} from 'A/ai.js'
export default {
  props: {
    visible: {
      default: false
    },
    selectType: {
      default: 'checkbox'
    },
    defaultSelected: {
      default() {
        return []
      }
    },
    devicemoduletype: {
      default: ''
    },
  },
  data() {
    return {
      moment,
      isDevice: false,
      terminaltypes: [],
      devicemoduletypes,
      modalVisible: false,
      modeltype:'',
      queryParams: {
        version: '',
        devicemoduletype: this.devicemoduletype||'',
      },
      tableLoading: false,
      tableColumns: [
        {
          title: '监测设备型号',
          dataIndex: 'terminaltypeid',
          key: 'terminaltypeid',
          ellipsis: true,
          scopedSlots: { customRender: 'terminaltypeid' }
        },
        {
          title: '设备类型',
          dataIndex: 'devicemoduletype',
          key: 'devicemoduletype',
          ellipsis: true,
          scopedSlots: { customRender: 'devicemoduletype' }
        },
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
        },
        {
          title: '版本文件',
          dataIndex: 'filename',
          key: 'filename',
          ellipsis: true,
        },
        {
          title: '版本描述',
          dataIndex: 'versiondesc',
          key: 'versiondesc',
          ellipsis: true,
        },
        {
          title: '更新时间',
          dataIndex: 'uploadtime',
          key: 'uploadtime',
          ellipsis: true,
          scopedSlots: { customRender: 'uploadtime' }
        },
      ],
      tableData: [],
      selectedRows: [],
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 5,
      }
    }
  },
  computed: {
    selectedRowKeys() {
      let currentSelectKeys = this.selectedRows.map(item => item.deviceversionid);
      return currentSelectKeys;
    },
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
    devicemoduletypesMap() {
      let result = {};
      devicemoduletypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.selectedRows = this.defaultSelected || [];
        this.init();
      }else {
        this.selectedRows = []
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      this.modalVisible = this.visible;
      if(!this.devicemoduletype) {
        this.isDevice = true;
        this.queryParams.devicemoduletype = '';
      }
      this.getTerminaltypes();
      this.resetDatas();
    },
    resetDatas() {
      this.versionObj = null;
      this.queryParams.devicemoduletype = this.devicemoduletype;
    },
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: ''+item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
        }
        this.getTableData();
      }).catch(err => {
        this.getTableData();
      })
    },
    resetQueryForm() {
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let params = {
        ...this.queryParams,
        // devicemoduletype: this.devicemoduletype,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getDeviceversionListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onSelectRow(record, selected, selectedRows, nativeEvent) {
      if(selected) {
        if(this.selectType == 'radio') {
          this.selectedRows = [record]
        }else if(this.selectedRowKeys.indexOf(record.deviceversionid) < 0) {
          this.selectedRows.push(record);
        }
      }else {
        this.selectedRows = this.selectedRows.filter(item => item.deviceversionid!=record.deviceversionid)
      }
    },
    onSelectAllRows(selected, selectedRows, changeRows) {
      if(selected) {
        this.tableData.forEach(record => {
          if(this.selectedRowKeys.indexOf(record.deviceversionid) < 0) {
            this.selectedRows.push(record);
          }
        })
      }else {
        this.tableData.forEach(record => {
          this.selectedRows = this.selectedRows.filter(item => item.deviceversionid!=record.deviceversionid)
        })
      }
    },
    sendMsg:function (){
      this.modeltype = this.selectedRows.map(item => item.devicemoduletype);
      this.version = this.selectedRows.map(item => item.version);
      this.$emit('getMsg',this.modeltype,this.version);
      // console.log(this.modeltype)
    },
    confirm() {
      this.$emit('get-selected-rows', this.selectedRows);
      this.modalVisible = false;
      this.sendMsg();
    },
    getTerminalTypeStr(text) {
      let result = [];
      if(text) {
        let typeArr = text.split(',');
        typeArr.forEach(item => {
          if(this.terminaltypesMap[item]) {
            result.push(this.terminaltypesMap[item]);
          }
        })
      }
      return result.join(',')
    }
  }
}
</script>